import React, { useEffect, useState } from "react"
import { graphql } from "gatsby"

import { useI18next, Link, FormatPrice, formatPrice } from "../i18n"
import { Image, ImageData, Icon, Carousel } from "../Layout/Components"
import { IconData } from "./DynamicRow"
import { Tooltip } from "antd"

export type BrandData = {
  name: string
  slug: string
  details: {
    published?: boolean
    logo: ImageData
  }
}

export type SegmentsData = {
  name: string
  slug: string
}

export type TechnologyData = {
  name: string
  slug: string
  details: {
    icon: IconData
  }
}

export type CarData = {
  title: string
  brands: {
    nodes: BrandData[]
  }
  tecnologies: {
    nodes: TechnologyData[]
  }
  segments: {
    nodes: SegmentsData[]
  }
  details: {
    uid: string
    imageHero: ImageData
    listPrice: {
      min: number
      max: number
    }
  }
}

type Props = {
  cars: CarData[]
  selectedBrands?: string[]
  selectedSegments?: string[]
  selectedTechnologies?: string[]
  selectedPriceRange?: [number, number]
  clbkHowManyCars?(data: { total: number; shown: number }): Function
  display?: "list" | "carousel"
  className?: string
  title?: string
}

export const Cars: React.FC<Props> = ({
  cars,
  selectedBrands,
  selectedSegments,
  selectedTechnologies,
  selectedPriceRange,
  clbkHowManyCars,
  display,
  className,
  title,
}) => {
  const { t } = useI18next()

  const [shownCars, setShownCars] = useState([])
  display = display || "carousel"
  title = title || t("Le nostre auto")

  useEffect(() => {
    // Filter cars
    let tmpShownCars = cars.filter((car) => {
      let show = true

      // Filter by technology
      if (selectedTechnologies && selectedTechnologies.length > 0 && !selectedTechnologies.includes(car.tecnologies.nodes[0].slug)) show = false
      // Filter by segment
      if (selectedSegments && selectedSegments.length > 0 && !selectedSegments.includes(car.segments.nodes[0].slug)) show = false
      // Filter by Brand
      if (selectedBrands && selectedBrands.length > 0 && !selectedBrands.includes(car.brands.nodes[0].slug)) show = false
      // Filter by Price
      if (selectedPriceRange && car.details.listPrice.min > selectedPriceRange[1]) show = false
      if (selectedPriceRange && car.details.listPrice.max < selectedPriceRange[0]) show = false

      return show
    })

    // Sort by minimum listprice
    tmpShownCars.sort((a, b) => a.details.listPrice.min - b.details.listPrice.min)

    setShownCars(tmpShownCars)
    if (clbkHowManyCars) clbkHowManyCars({ total: cars.length, shown: tmpShownCars.length })
  }, [cars, selectedBrands, selectedSegments, selectedTechnologies, selectedPriceRange])

  const renderedCars = shownCars.map((car, i) => {
    return (
      <Link to={"car:" + car.details.uid} className="card__car" key={i}>
        <div className="card__car-cover">
          <Image file={car.details.imageHero} className="card__car-image" type="contain" />
        </div>
        <div className="card__car-title">{car.title}</div>
        <div className="card__car-price">
          Prezzo: da <FormatPrice price={car.details.listPrice.min} decPlaces={0} /> a <FormatPrice price={car.details.listPrice.max} decPlaces={0} />
        </div>
        <div className="card__car-cta">
          <button className="btn btn--ghost">{t("Scopri di più")}</button>
          <div className="card__car-info-icons">
            <Tooltip title={car.segments.nodes[0]?.name} color="#163df7">
              <div>
                <Icon name="fal:car" size="s" />
              </div>
            </Tooltip>
            <Tooltip title={car.tecnologies.nodes[0]?.name} color="#163df7">
              <div>
                <Icon icon={car.tecnologies.nodes[0]?.details.icon} size="s" />
              </div>
            </Tooltip>
            <Tooltip title={car.brands.nodes[0]?.name} color="#163df7">
              <div>
                <Image file={car.brands.nodes[0]?.details.logo} className="card__car-brand-logo" type="contain" />
              </div>
            </Tooltip>
          </div>
        </div>
      </Link>
    )
  })

  switch (display) {
    case "list":
      return <div className="cars-list">{renderedCars}</div>
    case "carousel":
      return (
        <Carousel
          title={title}
          more={
            <Link to="cars" className="btn btn--ghost">
              Vedi tutte
            </Link>
          }
          className={className}
          initialIndex={Math.min(2, cars.length - 1)}
        >
          {renderedCars}
        </Carousel>
      )
  }
}

export default Cars

// Query fragment to include in graphql
export const query = graphql`
  fragment carData on Wordpress_Car {
    title
    brands {
      nodes {
        name
        slug
        details {
          logo {
            ...imageData
          }
        }
      }
    }
    tecnologies {
      nodes {
        name
        slug
        details {
          icon {
            icon
            iconStyle
            iconType
          }
        }
      }
    }
    segments {
      nodes {
        name
        slug
      }
    }
    details {
      imageHero {
        ...imageData
      }
      listPrice {
        max
        min
      }
      uid
    }
  }
  fragment carsData on Wordpress {
    cars(where: { language: $languageCode }, first: 1000) {
      nodes {
        ...carData
      }
    }
  }
  fragment carFiltersData on Wordpress {
    brands(where: { orderby: NAME, language: $languageCode }, first: 1000) {
      nodes {
        name
        slug
        details {
          published
          logo {
            ...imageData
          }
        }
      }
    }
    segments(where: { orderby: NAME, language: $languageCode }, first: 1000) {
      nodes {
        name
        slug
      }
    }
    tecnologies(where: { orderby: NAME, language: $languageCode }, first: 1000) {
      nodes {
        name
        slug
        details {
          icon {
            icon
            iconStyle
            iconType
          }
        }
      }
    }
  }
`
