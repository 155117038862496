import React, { useState } from "react"
import { graphql } from "gatsby"

import { useDispatch, useSelector } from "react-redux"
import * as actions from "../store/actions"

import GridLayout from "../Layout/GridLayout"

import { useI18next, Link, FormatPrice, formatPrice } from "../i18n"
import { Seo } from "../seo"

import { Breadcrumbs, Image, ImageData, Icon } from "../Layout/Components"

import HeroBlog from "../rows/HeroBlog"
import Testimonials from "../rows/Testimonials"
import Cars, { BrandData, CarData, SegmentsData, TechnologyData } from "../rows/Cars"

import { Select, Slider, Tooltip } from "antd"
const { Option } = Select

export default (props) => {
  const { t } = useI18next()
  const dispatch = useDispatch()

  // Data from graphql query
  const testimonials = props.data.wordPress.testimonials.nodes
  const brands: BrandData[] = props.data.wordPress.brands.nodes.filter((brand) => brand.details.published)
  const segments: SegmentsData[] = props.data.wordPress.segments.nodes
  const tecnologies: TechnologyData[] = props.data.wordPress.tecnologies.nodes
  const cars: CarData[] = props.data.wordPress.cars.nodes

  // Debug
  //console.log("Brands: ", brands)
  //console.log("Segments: ", segments)
  //console.log("Tecnologies: ", tecnologies)
  //console.log("Cars: ", cars)

  //console.log("Props: ", props)
  //console.log("Context: ", props.pageContext)

  // States
  const selectedBrands = useSelector((state) => state.cars.selectedBrands)
  const selectedSegments = useSelector((state) => state.cars.selectedSegments)
  const selectedTechnologies = useSelector((state) => state.cars.selectedTechnologies)
  const selectedPriceRange = useSelector((state) => state.cars.selectedPriceRange)

  const resetFilters = () => {
    dispatch(actions.setSelectedBrands([]))
    dispatch(actions.setSelectedSegments([]))
    dispatch(actions.setSelectedTechnologies([]))
    dispatch(actions.setSelectedPriceRange([0, actions.types.SET_SELECTED_PRICE_RANGE_MAX]))
  }

  // shownCars = [...shownCars, shownCars[0], shownCars[0]]

  const [howManyCars, setHowManyCars] = useState({ total: 0, shown: 0 })

  return (
    <GridLayout className="cars">
      <Seo title={t("Le auto")} />
      <Breadcrumbs data={[{ to: "/", label: t("Home") }, { label: t("Le auto") }]} />
      <HeroBlog image={props.data.wordPress.mediaItemBy} title={t("<b>Le auto</b> di Ecoverso")} category="" />
      <div className="row row--center row--no-grid pt-none cars-main-row">
        <div className="cars-filters">
          <div className="cars-filters-left">
            <div className="cars-filters-title">{t("Tecnologie")}</div>
            <Select
              mode="multiple"
              placeholder={t("Filtra per tecnologia")}
              allowClear
              showArrow
              className="cars-filters-select"
              value={selectedTechnologies}
              onChange={(t) => {
                dispatch(actions.setSelectedTechnologies(t as any))
              }}
            >
              {tecnologies.map((tech, i) => (
                <Option key={i} value={tech.slug}>
                  <Icon icon={tech.details.icon} strokeWidth={2} size="s" />
                  {tech.name}
                </Option>
              ))}
            </Select>
            <div className="cars-filters-title">{t("Segmenti")}</div>
            <Select
              mode="multiple"
              placeholder={t("Filtra per segmento")}
              allowClear
              showArrow
              className="cars-filters-select"
              value={selectedSegments}
              onChange={(t) => {
                dispatch(actions.setSelectedSegments(t as any))
              }}
            >
              {segments.map((segment, i) => (
                <Option key={i} value={segment.slug}>
                  {segment.name}
                </Option>
              ))}
            </Select>
          </div>
          <div className="cars-filters-right">
            <div className="cars-filters-title">{t("Marchi")}</div>
            <Select
              mode="multiple"
              placeholder={t("Filtra per marchio")}
              allowClear
              showArrow
              className="cars-filters-select"
              value={selectedBrands}
              onChange={(t) => {
                dispatch(actions.setSelectedBrands(t as any))
              }}
            >
              {brands.map((brand, i) => (
                <Option key={i} value={brand.slug}>
                  <Image file={brand.details.logo} className="cars-brands-select-icon" type="contain" />
                  {brand.name}
                </Option>
              ))}
            </Select>
            <div className="cars-filters-title">{t("Range di prezzo")}</div>
            <Slider
              min={0}
              max={100000}
              step={5000}
              range
              tipFormatter={(p) => formatPrice(p, 0) + "€"}
              marks={{ 0: "0€", 100000: "100.000€" }}
              value={selectedPriceRange}
              onChange={(t) => {
                dispatch(actions.setSelectedPriceRange(t as any))
              }}
            />
          </div>
        </div>
        <div className="cars-list-title">
          {howManyCars.shown} {t("Auto")}
          {howManyCars.shown != howManyCars.total ? (
            <>
              {" "}
              {t("su")} {howManyCars.total} [
              <span
                onClick={() => {
                  resetFilters()
                }}
                style={{ textDecoration: "underline", cursor: "pointer" }}
              >
                Azzera filtro
              </span>
              ]
            </>
          ) : null}
        </div>
        <Cars
          cars={cars}
          selectedBrands={selectedBrands}
          selectedSegments={selectedSegments}
          selectedTechnologies={selectedTechnologies}
          selectedPriceRange={selectedPriceRange}
          clbkHowManyCars={(v) => setHowManyCars(v)}
          display="list"
        />
      </div>
      {/* <Testimonials testimonials={testimonials} /> */}
    </GridLayout>
  )
}

//query($id: ID!, $tags: [String!]) {
export const query = graphql`
  query ($languageCode: Wordpress_LanguageCodeFilterEnum!) {
    wordPress {
      ...carFiltersData
      ...carsData
      testimonials(
        where: { language: $languageCode, orderby: { field: DATE, order: DESC }, tagSlugIn: ["auto-elettriche", "auto-ibride", "auto-ibride-plug-in"] }
        first: 10
      ) {
        nodes {
          ...testimonial
        }
      }
      mediaItemBy(mediaItemId: 1654) {
        ...imageData
      }
    }
  }
`
